/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React from "react";

/**
 * Extensions
 * placeholder extensions component for production
 * @param props
 */
const Extensions: React.FC = (props: any) => {
  return <></>;
};

export default Extensions;