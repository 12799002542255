/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
import {
  emeraldTheme as Emerald,
  sapphireTheme as Sapphire,
  emeraldTheme as FordChargers,
  //fordChargersTheme as FordChargers
} from "@hcl-commerce-store-sdk/react-component";
//import fordChargersTheme from "./fordChargersTheme";
const themes = { Emerald, Sapphire,FordChargers};
const CurrentTheme = themes["FordChargers"];
export { CurrentTheme };
