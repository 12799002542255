/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const GET_SEO_CONFIG = "GET_SEO_CONFIG";
export const GET_SEO_CONFIG_SUCCESS = "GET_SEO_CONFIG_SUCCESS";
