/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const OPEN_CONFIRMATION = "OPEN_CONFIRMATION";
export const CONFIRMATION_HANDLED = "CONFIRMATION_HANDLED";
export const CONFIRMATION_CANCELLED = "CONFIRMATION_CANCELLED";
export const CONFIRMATION_COMMS = "CONFIRMATION_COMMS";
