/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
//custom libraries
import { HOME } from "../../../constants/routes";
//custom redux
import { forUserIdSelector } from "../../../redux/selectors/user";

const StyledWrapper = styled.div`
  ${(props) => {
    const theme = props.theme;
    const match = useRouteMatch({
      path: HOME,
      strict: true,
      sensitive: true,
    });
    const forUserId = useSelector(forUserIdSelector);
    const url = window.location.href;
    const checkURL = url.includes('/payment');

    let headingClasses = "";

    for (let i = 1; i <= 6; i += 1) {
      headingClasses += `
        h${i}:not(.MuiTypography-root) {
         
        }
      `;
    }

    let paddingClasses = "";

    for (let i = 1; i <= 20; i += 1) {
      paddingClasses += `
        .horizontal-padding-${i} {
          padding-left: ${theme.spacing(i)}px;
          padding-right: ${theme.spacing(i)}px;
        }
        .vertical-padding-${i} {
          padding-top: ${theme.spacing(i)}px;
          padding-bottom: ${theme.spacing(i)}px;
        }
        .top-padding-${i} {
          padding-top: ${theme.spacing(i)}px;
        }
        .bottom-padding-${i} {
          padding-bottom: ${theme.spacing(i)}px;
        }
        .left-padding-${i} {
          padding-left: ${theme.spacing(i)}px;
        }
        .right-padding-${i} {
          padding-right: ${theme.spacing(i)}px;
        }
        .horizontal-margin-${i} {
          margin-left: ${theme.spacing(i)}px;
          margin-right: ${theme.spacing(i)}px;
        }
        .vertical-margin-${i} {
          margin-top: ${theme.spacing(i)}px;
          margin-bottom: ${theme.spacing(i)}px;
        }
        .top-margin-${i} {
          margin-top: ${theme.spacing(i)}px;
        }
        .bottom-margin-${i} {
          margin-bottom: ${theme.spacing(i)}px;
        }
        .left-margin-${i} {
          margin-left: ${theme.spacing(i)}px;
        }
        .right-margin-${i} {
          margin-right: ${theme.spacing(i)}px;
        }
      `;
    }

    return `
      font-family: ${theme.typography.fontFamily};

      ${paddingClasses}
      ${headingClasses}



    .content-box-shipping
    {
      background: #fff;
      background-clip: padding-box;
      border: 1px solid;
      /* border-radius: 5px; */
      color: #545454;
      display:flex;
    }
    .PrivateSwitchBase-input
    {
      display:hidden;
    }
    .labelClass-Shipping{

      align-items: baseline;
    }

 //Style for terms and condition payment page
    .TermsAndCondition
    {
      text-Align: left;
      color: #4d4d4d!important;
      font-size: 14px;
      font-style: normal;
      letter-spacing: 1px;
      line-height: normal!important;
      font-weight: 300;
      letter-spacing: 1px;
      font-family: Ford Antenna;
      word-wrap: break-word;
      word-break: break-word;
      padding:5px;
    }  
    .TermsAndCondition > a {
      font-size: 13px !important;
    }
    svg {
      color: #00095b;
    }

    .radio__label, .checkbox__label {
      cursor: pointer;
      vertical-align: middle;
      display: table-cell;
      width: 100%;
    }

  .content-box__emphasis {
    font-weight: 600;
    color: #333333;
    padding:5px;
  }

  .checkout-terms-checkbox__content {
    padding: 20px 40px;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }
  .checkout-terms-checkbox__label {
    display: flex;
    align-items: center;
  }

  .checkout-terms-checkbox__label:not(:last-child) {
      margin-bottom: 15px;
  }

  .checkout-terms-checkbox__label {
    display: flex;
    align-items: center;
  }

  .checkout-terms-checkbox__label:not(:last-child) {
      margin-bottom: 15px;
  }
  .checkout-terms-checkbox__input-wrapper {
    margin-left: -20px;
    margin-right: 1px;
  }
  .section {
    position: relative;
    padding-top: 2em;
  }
  .section__content {
    zoom: 1;
  }
  .radio__input, .checkbox__input {
    padding-right: 0.75em;
    white-space: nowrap;
  }
  .content-box {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 5px;
    color: #545454;
  }
  
  .content-box__row~.content-box__row {
    border:3px;
    border-top: 1px solid #d9d9d9;
  }

  .content-box__row + .content-box__row--no-border {
    border-top: none;
    padding-top: 0;
}
  
  .order-confirmation-section .main .content-box {
    margin-top: 1rem;
  }
  .bold {
    font-weight: bold;
  }
  .visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
    width: 2px;
    height: 2px;
    margin: -2px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
  }
//pagination

.MuiPagination-root {
  .MuiPagination-ul {
     flex-wrap: nowrap;
     li {
       &:first-child {
         flex-basis: 100%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
        
         > button::after {
           margin-left: 10px;
           content: 'Prev';
           color:#102b4e;
           font-size:14px;
         }
       }
       &:last-child {
         flex-basis: 100%;
         display: flex;
         justify-content: flex-end;
         align-items: center;
         
         > button::before {
           margin-right: 10px;
           content: 'Next';
           color:#102b4e;
           font-size:14px;
         }
       }
     }
   }
 }

.MuiPaginationItem-page.Mui-selected::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #102b4e ;
    position: absolute;
    bottom: -10px;
    left: 0;
   

}
.MuiPaginationItem-page.Mui-selected{
background-color:none !important;
  }

.MuiPaginationItem-root{
  border-radius: 0px;
 font-size:16px;
 
}
.MuiPaginationItem-page.Mui-disabled {
  opacity:1;
}
.MuiPaginationItem-icon{
  height:30px;
  width:30px;
  color:#102b4e;
  font-weight:200px
  font-size:14px
}
      //PDP Styling
      .product-add-to-cart{
        margin :10px;
        height: 50px;
        width: 100%;
        font-size: 20px;
      }
#mag_container{
  width:110%;
  height:60%;
  overflow:hidden;
  border:3px solid #fff;
  position:relative;
}
#mag_img{
  max-width:100%;
  min-width:100%;

  position:absolute;
  left:50%;
  top:30%;
  transform:translate(-50%,-50%);

  pointer:zoom-out;
}




      .img-zoom-lens {
        position: absolute;
        border: 1px solid #d4d4d4;
        /*set the size of the lens:*/
        width: 40px;
        height: 40px;
      }
      .img-zoom-result {
        border: 1px solid #d4d4d4;
        /*set the size of the result div:*/
        width: 600px;
        height: 600px;
      }
      .image-container-to-zoom
      {
        position:relative ;
       
      }
      .image-container-to-zoom img
      {
         width:100%;
         display:block;
         position:absolute;
      }

      .image-container-to-zoom:hover
      {
        cursor:zoom-in;
      }






      .Slider-pdp{
        height:500px;
      }




      //
      //Nav Bar style
      .site-header, #HomePage, .container-center {
        max-width: 1440px;
        width: 100%;
        margin: auto;
      }
      
      .site-header-main1 {
        height: 100px;
        width: 100%;
     
      padding: 0 30px;
      padding-top:15px;
    }
      .site-header__nav-link-toggle1
      {
        
      margin-top: 10px;
      margin-left: 2px;
      
      
     
      background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/678/assets/icon-chevron-down-secondary.svg);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      height: 8px;
    
      width: 15px;
      }


      //Featured Rides style
      .banner-title1{
        margin-bottom: 30px;
        margin-top: 0;
        letter-spacing: 4px;
        font-size: 34px;
        color: #4d4d4d;
        font-weight: 300;
      }
      .product-image1{
        width:100%;
        height:100%
      }
      .card-height1{ 
        height:200px;
        position:relative;
      }
      .topHeader{
        overflow:hidden;
      }
    .absoulte-content{
      position: absolute ;
      top: 10%;
    }
    .offer-banner-title1{
      font-size: 24px;
      line-height: 34px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 3px;
      color:#fff;
      width: 460px;
      max-width: 100%;
    }

  
    // Popular Product Style

    .Parent
    {
      position:relative;
    }
    .buttonBackPDP
    {
      left:-20px;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
        height: ${theme.spacing(7)}px;
        width: ${theme.spacing(7)}px;
        border: none;
        border-radius: 50%;
        background: none;
        border: 1px solid transparent;
    
        &:hover {
          cursor: pointer;
          color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.light};
        }
      
    }
    .buttonNextPDP
    {
      right:-10px;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
        height: ${theme.spacing(7)}px;
        width: ${theme.spacing(7)}px;
        border: none;
        border-radius: 50%;
        background: none;
        border: 1px solid transparent;
    
        &:hover {
          cursor: pointer;
          color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.light};
        }
      
    }
   
    .buttonBack
    {
      left:0;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
        height: ${theme.spacing(5)}px;
        width: ${theme.spacing(5)}px;
        border: none;
        border-radius: 50%;
        background: #102b4e;
        border: 1px solid transparent;
        
    
        &:hover {
          cursor: pointer;
          color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.light};
        }

        svg{
          fill:#fff;
          width:2rem;
          height:2rem;
        }
      
    }
    .buttonNext
    {
      right:0;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
        height: ${theme.spacing(5)}px;
        width: ${theme.spacing(5)}px;
        border: none;
        border-radius: 50%;
        background: #102b4e;
        border: 1px solid transparent;
    
        &:hover {
          cursor: pointer;
          color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.light};
        }

        svg{
          fill:#fff;
          width:2rem;
          height:2rem;
        }
      
    }
    .LeftButtonPDP
    {
      font-size: 4em !important;
    }
    .RightButtonPDP
    {
      font-size: 4em !important;
    }
    // .slideHorizontal___1NzNV
    // {
    //   margin:30px !important;
    //   width:230px !important;
    // }
    .image-style{
      width:100%;
      height:100%;
    }
    @media only screen and (max-width: 600px) {
    .image-style{
      width:350px;
      height:350px;
    }
  
  }
  .image-container1{
    width: 100%;
    height: 150px !important;
    display: block;
    position: relative;
  }
  .image-container-title1{
  
   .MuiContainer-root {
    margin-left:0px
   }
   
  }
  .nosto-product-info
  {
    text-align: left;
    font-family: inherit !important;
    color: #4d4d4d;
    margin-left: 8px;
    width:220px;
  }
  .nosto-product-brand
  {
    color: #4d4d4d!important;
  font-size: 11px!important;
  line-height: 11px!important;
  font-weight: 500!important;
  letter-spacing: 1.1px!important;
  margin-bottom: 5px!important;
  }

  .nosto-product-name
  {
    color: #4d4d4d!important;
  font-size: 16px!important;
  line-height: 22px!important;
  font-weight: 400!important;
  letter-spacing: 1.6px!important;
  margin-bottom: 15px!important;
  display: block!important;
  max-height: 40px!important;
 
  }
    .title {
        text-align: left ;
        color: #4d4d4d;
        font-size: 24px ;
        line-height: 34px ;
        font-weight: 300 ;
        letter-spacing: 3px ;
        text-transform: uppercase ;
        margin-bottom: 40px ;
    
    }
    
   .brand-name1 {
        color: #4d4d4d !important;
        font-size: 11px;
        line-height: 11px;
        font-weight: 500;
        letter-spacing: 1.1px ;
        margin-bottom: 5px ;
    }
    .brand-info1{
      margin-top:15px;
      padding: 0 !important;
    }
    
    .product-discription1 {
        color: #4d4d4d;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: 1.6px;
        margin-bottom: 15px;
        display: block;
        max-height: 40px;
        overflow: hidden;
    }
    
    .product-price1 {
        color: #102b4e;
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: 2px;
        margin-top: 0;
    }

      // Service Footer//
      //SVG//
      .icon-banner1
      {
        box-shadow: 3px 3px 4px #00000040;
      
      }
 

      // shop accessories start css

      .shop-accessories-grid{
        display: grid;
        grid-gap: 14px;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: repeat(3,250px);
      }
      .grid-overflow{
        overflow:hidden;
      }
      .grid--style-1{
        grid-area: 1 / 1 / 3 / 3;
      }
      .grid--style-2{
        grid-area: 1 / 3 / 2 / 4;
      }
      .grid--style-3{
        grid-area: 1 / 4 / 2 / 5;
      }
      .grid--style-4{
        grid-area: 3 / 1 / 4 / 2;
      }
      .grid--style-5{
        grid-area: 3 / 2 / 4 / 3;
      }
      .grid--style-6{
        grid-area: 2 / 3 / 4 / 5;
      }

      // shop accessories end css


.cls-1,.cls-4{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3{fill-rule:evenodd;}.cls-4{stroke:#000;stroke-width:0.75px;}
      // End -SVG//
      .service-title1{
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 26px;
        font-weight: 400;
        text-transform: none;
        font-stretch:initial;
      }
    
      .service-caption1{  
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 21px;
        font-weight: 400;
        text-transform: none;
        font-stretch:initial;
      }
    //

      .vertical-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .horizontal-center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .horizontal-center.vertical-center,
      .full-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .full-height {
        height: 100%;
      }

      .full-width {
        width: 100%;
      }
      #CheckOutPage .breadcrum-section {
        margin-top:48px;
        padding-bottom: 0;
        margin-bottom: 2em;
        padding-left: 48px;
      }

      #CheckOutPage fieldset.MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        // border-color: rgba(0, 0, 0, 0.23);
    }
  

      .breadcrum-item {
        display:flex;
        font-size: 0.8571428571em;
      }
      .breadcrum-item svg {
        margin: 0 0.6428571429em;
      }
      #CheckOutPage aside {
         color: white;
          background:#00142E;
          width: 100%;
        }
      #CheckOutPage .MuiInputLabel-outlined,
      #CheckOutPage .inputLabel label
       {
        font-size: 0.8571428571em !important;
      }
      #CheckOutPage .MuiButton-containedPrimary {
        background: #00095b;
        border: 1px transparent solid;
        color: white;
        padding: 1.4em 1.7em;
        font-size: 1.4rem;
        font-weight: 500;
      }

      #CheckOutPage .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 8px 12px;
      }

      #CheckOutPage .state-input .MuiFormControl-root,
      #CheckOutPage .inputLabel  {
        margin-bottom: 0 !important;
      }

      .checkout-message {
        margin-top: 15px;
        padding: 10px 20px;
        background-color: #2861a4;
        color: #FFF;
        border-radius: 5px;
    }
    .checkout-message a {
      color: #FFF;
      text-decoration: underline;
    }

    .border-shadow-none {
      border:none;
      box-shadow: none;
      background: transparent;
    }
    .address-card.bottom-line {
      border-bottom: 1px solid #d9d9d9;
      border-radius: 0;
    }
    .bottom-line {
      border-bottom: 1px solid #d9d9d9 !important;
      border-radius: 0 !important;
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: #00095b;
    }
    .MuiCardActions-spacing .MuiButton-text:not(.Mui-disabled) {
      color: #00095b;
    }
    .content-box-shipping label {width: 100%}
    .content-box-shipping label > span:last-child {width: 100%}

    .border-section {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      margin-bottom: 3em;
    }

    .term-condition-margin .TermsAndCondition {
      margin-top: 13px;
    }

    #CheckOutPage {
      ${
        checkURL ? `position : relative;` : ``
      }
    }

    @media (min-width: 1000px) {
      .sidebar {
          width: 40%;
          padding: 4em 4%;
          background-position: left top;
          float: right;
          background: #00142E;
          top: 0;
          position: absolute;
          right: 0px;
          bottom: -1px;
          width: 45%;
      }
  }

    #CheckOutPage .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: #00095b; border-width:2px !important;}

      .full-viewport-height {
        ${
          forUserId !== undefined ? `min-height: 1000px;` : `min-height: 100vh;`
        }
      }

      .full-viewport-width {
        width: 100vw;
      }

      .text-align-center {
        text-align: center;
      }

      .text-align-right {
        text-align: right;
      }

      .text-align-left {
        text-align: left;
      }

      .marketing-button {
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.button.fontSize}px;
        border-radius: ${theme.shape.borderRadius}px;
        padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
        letter-spacing: 0.02rem;
        box-shadow: none;
        color: ${theme.palette.text.secondary};
        line-height: 1.75;
        font-weight: 500;
        border: 1px solid ${theme.palette.text.secondary};
        background: white;
        cursor: pointer;
        background: white;
        transition: all ${theme.transitions.duration.standard}ms ${
      theme.transitions.easing.easeIn
    };

        &:hover {
          border-color: ${theme.palette.primary.dark};
          color: ${theme.palette.primary.dark};
        }

        &.primary {
          background: ${theme.palette.primary.main};
          border-width: 0;
          color: white;

          &:hover {
            background: ${theme.palette.primary.dark};
          }
        }
      }


      }

      img {
        max-width: 100%;
      }

      ${
        match && match.isExact
          ? `
          @supports ((perspective: 1px) and (not (-webkit-overflow-scrolling: touch))) {
            // ${forUserId === undefined && `height: 100vh;`}
            ${forUserId === undefined}
            overflow-x: hidden;
            overflow-y: auto;
            // perspective: 3px;
            perspective-origin: 50% 50%;

            .parallax__layer {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            .parallax__layer--base {
              transform: translateZ(0);
            }

            .parallax__layer--back {
              transform: translateZ(-1px) translateY(-80%) translateX(-25%);

              img {
                max-width: 150%;
                width: 150%;
              }

              ${theme.breakpoints.down("xl")} {
                transform: translateZ(-1px) translateY(-150%) translateX(-25%);
              }

              ${theme.breakpoints.down("lg")} {
                transform: translateZ(-1px) translateY(-120%) translateX(-25%);
              }

              ${theme.breakpoints.down("md")} {
                transform: translateZ(-1px) translateY(-80%) translateX(-25%);
              }

              ${theme.breakpoints.down("sm")} {
                transform: translateZ(-1px) translateY(-80%) translateX(-30%);
                img {
                  max-width: 250%;
                  width: 250%;
                }
              }
            }
          }
        `
          : ``
      }

    `;
  }}
`;

export default StyledWrapper;
