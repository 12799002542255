import "./static.scss";
import React, { Component,useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { loginStatusSelector, logonIdSelector, userNameSelector } from "../../../redux/selectors/user";

import { useSelector, useDispatch } from "react-redux";
import { useSite } from "../../../_foundation/hooks/useSite";
//Adobe Tag Service
import AdobeService from "../../../_foundation/adobeService/adobeService";


const LearnMoreRewards=()=>{
  const isUserLoggedIn = useSelector(loginStatusSelector);
  const { mySite } = useSite();

  let url = window.location.href;
  let protocol = window.location.protocol;
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  let currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`;
  const [callAdobeTags, setCallAdobeTags] = useState(false);

  //Adobe Tag Manager
  useEffect(() => {
    if (mySite && !callAdobeTags) {
        (async () => {
            let res = await AdobeService.track({
            pageName: "ford pass rewards:learn more",
            variantName: "global-load",
            siteSection: "fpr",
            hierarchy: "fpr",
            eventType: "globalLoad",
            });
            AdobeService.callSatellite(res);
        })();
    setCallAdobeTags((prev) => true);
    }
  }, []);

    return ( 
      <div className="learn-more-page">
        <div id="shopify-section-standard__hero" className="shopify-section">
          <section className="page-hero" id="page-hero-">
            <div className="page-hero__background object-cover page-hero__overlay ">
              <img
                className="above-mobile-large"
                src="https://chargers.ford.com/gcs/B2C/chargers/Chargers-Reward/LearnMore_HeroBanner.png"
                data-preload="loaded"
                alt="Desktop Hero" width={'2880'} height={'662'}/>
              <img
                className="mobile-large-only"
                src="https://chargers.ford.com/gcs/B2C/chargers/Chargers-Reward/LearnMore_HeroBanner.png"
                data-preload="loaded"
                alt="Mobile Hero" width={'2880'} height={'662'} />
            </div>
            <div className="page-hero__content container">
              <div className="page-hero__content-inner page-hero__content-inner--middle">
                <h1 className="page-hero__heading title--md_learn">Learn more about FordPass® Rewards</h1>
              </div>
            </div>
          </section>
        </div>

        <div id="shopify-section-standard__content" className="shopify-section">
          <section className="content-section container--mb-2">
            <div className="page__content rte">
              <br></br>
              <br></br>
              <h1 style={{ color: "#4d4d4d",lineHeight:'1em',height:40,fontSize:36, fontWeight:400}}>Buy a Charger, Get Rewarded</h1><br />
              <p>
              Redeem FordPass® Rewards Points for discounts on Ford Chargers.
              </p><br></br>
              <p>
              FordPass® Rewards** takes your ownership experience to a new level. When you join, you’ll earn Points for things like service and purchases – online and at participating dealerships and retailers. Then, you can redeem the Points you’ve earned on service, chargers, parts, accessories, or even a down payment on your next Ford or Lincoln vehicle.
              </p><br />

              {/* <p>
              <br></br>
              It’s easy to join. Sign in below or download the FordPass App or Lincoln Way App to your smart phone and start earning Points.
            </p> */}

            <div className="learnMore"
                style={{
                  marginTop: "3rem",
                  marginBottom: "3rem",
                  textAlign: "left"
                }}>

                {isUserLoggedIn ?
                  <>
                    <p>You can redeem Points during checkout.</p><br></br>
                    <Link to="/" style={{ borderRadius: "20px" }} className="btn btn--rounded">Shop Chargers</Link>

                  </>
                  :
                  <>
                    <p>It's easy to join. Sign in or download the FordPass App to your smart phone and start earning Points.</p><br></br>
                    <a href={FMA_LOGIN_LINK} style={{ borderRadius: "20px" }} className="btn btn--rounded" >
                      Sign In
                    </a>
                  </>
                }
            </div>

            </div>
          </section>
        </div>
      </div>
    );
  }

export default LearnMoreRewards;