/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const CLICK_EVENT_TRIGGERED = "CLICK_EVENT_TRIGGERED";
export const TRIGGER_MARKETING = "TRIGGER_MARKETING";
