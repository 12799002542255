/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSite } from "../../../_foundation/hooks/useSite";
import { CircularProgress } from "@material-ui/core";

const AfterLogout: React.FC = () => {
  const { mySite } = useSite();
  const storeId = mySite.storeID;
  const logoutResponse = window.location.search;
  const searchParams = new URLSearchParams(logoutResponse);
  const currentUrl = searchParams?.get('currentURL'); 

  useEffect(() => {
    if (logoutResponse) {
      axios
        .delete(`/wcs/resources/store/${storeId}/loginidentity/@self?rememberMe=false`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (currentUrl) {
            window.location.assign(decodeURIComponent(currentUrl)); 
          } else {
            window.location.assign(decodeURIComponent(window.location.origin)); 
          }
        })
        .catch((error) => {
          console.error("Logout request failed:", error);
        });    }
  }, [logoutResponse]);


  return (
    <div style={{
      display: 'flex',
      height: '80vh',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CircularProgress />
    </div >
  );
};

export { AfterLogout };
