/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//Custom libraries
import { OrderItemTable } from "../../widgets/order-item-table";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
//UI
import { Divider } from "@material-ui/core";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledIconButton,
  StyledPaper,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { numItemsSelector } from "../../../redux/selectors/order";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router";
import { Link } from "react-router-dom";
import { MiniCartOrderList } from "./order-list";
import * as orderActions from "../../../redux/actions/order";
import * as ROUTES from "../../../constants/routes";
//Adobe Tag Service
import AdobeService from "../../../_foundation/adobeService/adobeService";

interface MiniCartContentProps {
  title: string;
  orderType: string;
  orderItems: any[];
  subtotalLabel: string;
  subtotal: number | null;
  subtotalCurrency: string;
  emptyCartMsg: string;
  cartLinkLabel: string;
  checkoutLinkLabel: string;
  handleCartOnClick: Function;
  handleCheckoutOnClick: Function;
  handleClose: Function;
  isOrderOwner: boolean;
  isB2B: boolean;
  //oneItemPerOrderMsg:boolean;
}

const StyledMiniCartContent = React.forwardRef<any, MiniCartContentProps>(
  (props: any, ref: any) => {
    const dispatch = useDispatch();
    const {
      title,
      orderType,
      orderItems,
      subtotalLabel,
      subtotal,
      subtotalCurrency,
      emptyCartMsg,
      cartLinkLabel,
      checkoutLinkLabel,
      handleCartOnClick,
      handleCheckoutOnClick,
      handleClose,
      isOrderOwner,
      isB2B,
     // oneItemPerOrderMsg,
    } = props;
    const numItems = useSelector(numItemsSelector);
    const history=useHistory();

    const cleartCart = () => {
      //Adobe Tag Manager starts
      (async () => {
          let res = await AdobeService.track({pageName: 'product details:shopping cart', onclickLinkName: 'fcg:product details:shopping cart:content action', onClickName: 'fcg:product details:shopping cart:content action:clear cart' , siteSection: 'product', hierarchy: 'product details', variantName: 'remove-cart', productName: orderItems[0]?.name, partNumber: orderItems[0]?.partNumber, quantity: orderItems[0]?.quantity, unitPrice: orderItems[0]?.unitPrice, availability: orderItems[0]?.orderItemInventoryStatus, eventType: 'removeCart'});
          AdobeService.callSatellite(res);
      })()
      //Adobe Tag Manager ends

      dispatch(orderActions.RESET_CART_ACTION());
      dispatch(orderActions.CLEAR_CART_ACTION());
    }
    
    return (
      <>
      
        {orderItems.length > 0 && (
          <>
      <div className="flex f-flex-end">
            <button className="slideout-cart__close close-cross" onClick={(e) => { handleClose(e) }} data-change-className="
[
    {
        &quot;elem&quot;:&quot;body&quot;,
        &quot;class&quot;:&quot;cart-open&quot;,
        &quot;action&quot;:&quot;remove&quot;
    },
    {
        &quot;elem&quot;:&quot;.slideout-cart__wrapper&quot;,
        &quot;action&quot;:&quot;remove&quot;,
        &quot;class&quot;:&quot;infront&quot;,
        &quot;delay&quot;:300
    }
]
" aria-label="Close (esc)">
                <span className="visually-hidden">Close (esc)</span>
            </button>
        </div>
      <div className="slideout-cart__header flex f-space-between f-vertical-bottom">
            <h2 className="slideout-cart__title">Shopping Cart</h2>
            <p>{numItems} item</p>
        </div>
     {/*oneItemPerOrderMsg  &&   <div className="slideout-cart__announcement slideout-cart__announcement--unable text-align-center">
            <p>Limited to one per order. Please remove the item in your cart to add your new selection.</p>
</div>*/}
       
            
              <MiniCartOrderList
                data={orderItems}
                miniCartView={true}
                handleMiniCartClose={handleClose}
              />
          
         

            <div className="slideout-cart__footer text-align-center m-t-m">

<div className="slideout-cart__subtotal flex f-vertical-center f-space-between">
    <h3 className="slideout-cart__subtotal-title">Subtotal:</h3>
    <p className="slideout-cart__subtotal-price" data-cart-total="">
      <FormattedPriceDisplay
        min={subtotal}
        currency={subtotalCurrency}
      />
    </p>
    </div>
        <div className="slideout-cart__subtotal flex f-vertical-center f-space-between">
            <h3 className="slideout-cart__subtotal-title">Shipping:</h3>
            <p className="slideout-cart__subtotal-checkout">Calculated at checkout</p>
        </div>

        <p className="m-t-m m-b-s">
        <Link to="/checkout" className="slideout-cart__cta button button--checkout">Continue to checkout</Link>
        </p>
        <p>
            <Link to="/cart" onClick={handleCartOnClick} className="link--with-arrow">View Cart</Link>
        </p>
        <p className="m-t-m">
            <Link to="/cart"  onClick={() => cleartCart()}>Clear Cart</Link>
          
        </p>
      </div>



          </>
        ) }

        
       
     </>
    );
  }
);

export default StyledMiniCartContent;