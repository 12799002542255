/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

export const LS_LANG_CHANGE = "LS_LANG_CHANGE";
export const LS_LANG_CHANGE_DONE = "LS_LANG_CHANGE_DONE";
