/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const HANDLE_SUCCESS_MESSAGE = "HANDLE_SUCCESS_MESSAGE";
export const RESET_SUCCESS_MESSAGE = "RESET_SUCCESS_MESSAGE";
