import React, { useState } from "react";
//UI
import { useSite } from "../../_foundation/hooks/useSite";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import { EMPTY_STRING } from "../../constants/common";
import HTMLReactParser from "html-react-parser";
import { useLocation } from "react-router-dom";

const Disclosure: React.FC = (props: any) => {
  const { mySite } = useSite();
  const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const catalogID: string = mySite ? mySite.catalogID : EMPTY_STRING;
  const [disclosureHtml, setDisclosureHtml] = useState("");
  const [displayHeader, setDisplayHeader] = React.useState<any>(true);
  const location = useLocation();

  React.useEffect(() => {
    let value;
    value =
      location.pathname.includes("checkout") ||
      location.pathname.includes("order-confirmation")
        ? false
        : true;
    setDisplayHeader(value);
  }, [location]);

  const initMarketingContent = () => {
    const parameters: any = {
      storeId: storeID,
      name: "USWBC_Espot_Bottom",
      catalogId: catalogID,
      query: {
      contentName: "USWBC_Espot_Bottom"
      },
      };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot =
          res.data.MarketingSpotData[0].baseMarketingSpotActivityData[0];
        if (eSpotRoot?.marketingContentDescription[0]?.marketingText) {
          setDisclosureHtml(
            eSpotRoot?.marketingContentDescription[0]?.marketingText
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    if (mySite) {
      initMarketingContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  return  displayHeader && (<div>{HTMLReactParser(disclosureHtml)}</div>);
};

export default Disclosure;
