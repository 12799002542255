/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { numItemsSelector } from "../../../redux/selectors/order";
import {useHistory} from "react-router";
import * as ROUTES from "../../../constants/routes";
// //Foundation libraries
// import { useSite } from "../../_foundation/hooks/useSite";
// //Custom libraries
// import { CONSTANTS } from "../../constants/order-item-table";
// import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
// import { INVENTORY_STATUS } from "../../constants/order";
// import { PAGINATION_CONFIGS } from "../../configs/order";
// //Redux
// import { currentContractIdSelector } from "../../redux/selectors/contract";
// import * as orderActions from "../../redux/actions/order";
// import {
//   forUserIdSelector,
//   loginStatusSelector,
//   userIdSelector,
// } from "../../redux/selectors/user";
//UI
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
    StyledAvatar,
    StyledGrid,
    StyledTypography,
    StyledNumberInput,
    StyledIconButton,
    TableColumnDef,
} from "@hcl-commerce-store-sdk/react-component";
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { forUserIdSelector, loginStatusSelector, userIdSelector } from "../../../redux/selectors/user";
import { INVENTORY_STATUS } from "../../../constants/order";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
import { cartSelector } from "../../../redux/selectors/order";
import { useWinDimsInEM } from "../../../_foundation/hooks/use-win-dims-in-em";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { CONSTANTS } from "../../../constants/order-item-table";
import * as orderActions from "../../../redux/actions/order";
import { XS_MOBILE_W } from "../../../constants/common";
//Adobe Tag Service
import AdobeService from "../../../_foundation/adobeService/adobeService";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
//GA360
// import AsyncCall from "../../_foundation/gtm/async.service";
// import { cartSelector } from "../../redux/selectors/order";
// import { useWinDimsInEM } from "./use-win-dims-in-em";
// import { XS_MOBILE_W } from "../../constants/common";

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const MiniCartOrderList = (props: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mySite } = useSite();
    const CancelToken = Axios.CancelToken;
    let cancels: Canceler[] = [];
    const contractId = useSelector(currentContractIdSelector);
    const loginStatus = useSelector(loginStatusSelector);
    const numItems=useSelector(numItemsSelector);
    const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;
    const history=useHistory();
    const dataProps = props.data;
    localStorageUtil.set('ShippingType', 'SHIPPING');
    /**
     * Initialize table data by making a copy
     * Material-table alters the input data, so data cannot be of immutable type
     * @returns Copy of the data prop
     */
    const data = useMemo(() => {
        const newData = (dataProps ?? []).map((oi) => ({ ...oi }));
        localStorageUtil.set('cartItems', newData)
        return newData;
    }, [dataProps]);

    const readOnly = props.readOnly !== undefined ? props.readOnly : true;
    const miniCartView =
        props.miniCartView !== undefined ? props.miniCartView : false;

    const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
    //update this flag as need later
    const pagination = !readOnly && !miniCartView;
    const handleMiniCartClose =
        props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;
    /**
     * Initialize quantity data per order item
     * @returns quantities object for each order item
     */
    const initQuantityData = () => {
        let newData: any = {};
        if (dataProps) {
            dataProps.map((oi) => {
                if (oi.quantity) {
                    try {
                        const parsedQty = parseInt(oi.quantity);
                        if (parsedQty > 0) {
                            newData[oi.orderItemId] = parsedQty;
                        }
                    } catch (e) {
                        console.log("Could not parse quantity");
                    }
                }
                return null;
            });
        }
        return newData;
    };
    const [quantityList, setQuantityList] = useState<any>(initQuantityData());
    const defaultOptions = {
        toolbar: false,
        header: !miniCartView,
        // paging: pagination,
        // pageSize: PAGINATION_CONFIGS.pageLimit,
        // pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
        actionsColumnIndex: -1,
        fixedColumns: {
            left: 0,
            right: 0,
        },
    };
    let options = props.options !== undefined ? props.options : defaultOptions;
    options.paging = false;
    const payloadBase: any = {
        currency: defaultCurrencyID,
        contractId: contractId,
        widget: "Order Item Table",
        cancelToken: new CancelToken(function executor(c) {
            cancels.push(c);
        }),
    };
    const QuantityCell = ({ rowData, ...props }) => {
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const cart = useSelector(cartSelector);
        const itemMemberId = rowData.xitem_memberId;
        const { w } = useWinDimsInEM();
        const mobile = !miniCartView && w > XS_MOBILE_W ? true : undefined;
        const disabled =
            rowData.freeGift === "true" ||
            (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch quantity update action for order item
         * @param item The selected order item
         */
        const onQuantityUpdate = (quantityString: string, item: any) => {
            if (item) {
                try {
                    const quantity = parseInt(quantityString);
                    if (quantity > 0) {
                        let payload = {
                            ...payloadBase,
                            quantity: quantity.toString(),
                            orderItemId: item.orderItemId,
                            fetchCatentries: true,
                        };
                        dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

                        // update this (even tho it's temporary) just so that once the dispatch
                        //   action completes, we don't "temporarily" revert back to the old value
                        quantityList[rowData.orderItemId] = quantity;
                    }
                } catch (e) {
                    console.log("Could not parse quantity");
                }
            }
        };
        return (<div>
            <StyledNumberInput
                mobile={false}
                className="product-quantity__input "
                value={quantityList[rowData.orderItemId]}
                min={1}
                step={1}
                precision={0}
                disabled={disabled}
                onChange={(event) => onQuantityUpdate(event, rowData)}
                stopLoadingOnUpdateValue={rowData}
                debounceTiming={250}
                strict
            />
        </div>);
    };
    const DeleteActionCell = ({ rowData, ...props }) => {
        const cart = useSelector(cartSelector);
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const itemMemberId = rowData.xitem_memberId;
        const [clicked, setClicked] = useState<boolean>(false);
        const disabled =
            clicked ||
            rowData.freeGift === "true" ||
            (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch action to remove selected order item
         * @param item The selected order item
         */
        const asynRemoveItem = async(item: any) => {
              //Adobe Tag Manager starts
              (async () => {
                let res = await AdobeService.track({pageName: 'product details:shopping cart', onclickLinkName: 'fcg:product details:shopping cart:content action', onClickName: 'fcg:product details:shopping cart:content action:remove' , siteSection: 'product', hierarchy: 'product details', variantName: 'remove-cart', productName: item?.name, partNumber: item?.partNumber, quantity: item?.quantity, unitPrice: item?.unitPrice, availability: item?.orderItemInventoryStatus, eventType: 'removeCart'});
                AdobeService.callSatellite(res);
            })()
            //Adobe Tag Manager ends
            const orderItemId = item.orderItemId;
            let payload = {
                ...payloadBase,
                orderItemId: orderItemId,
                fetchCatentries: true,
            };
            setClicked(true);
            if (dataProps.length === 1) {
                //Remove last Item Action - cart
                dispatch(orderActions.REMOVE_ALL_ITEM_ACTION(payload));
                localStorageUtil.remove('cartItems');
            } else{
                dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
            }

            //GA360
            if (mySite.enableGA) {
                AsyncCall.sendRemoveFromCartEvent(item, {
                    enableUA: mySite.enableUA,
                    enableGA4: mySite.enableGA4,
                });
            } 
            history.push(ROUTES.CART);
            /* (async () => {
                let res = await AdobeService.track({ pageName: 'cart:cart view', 
                onclickLinkName: 'cart:remove', 
                onclick: 'cart:remove', 
                siteSection: 'cart', 
                hierarchy: 'cart:cart view',
                SKU:item.partNumber,
                orderItemId: item.orderItemId, 
                quantity: item.quantity,
                name: item.name,
                unitPrice: item.unitPrice, 
                availability:item.orderItemInventoryStatus,
                totalCost: item.orderItemPrice,
                variantName: 'remove-cart', 
                eventType: 'removeCart' });
                AdobeService.callSatellite(res);
            })() */
            
        };
        const removeItem = async (item: any) => {
            //Remove Item from Cart
            await asynRemoveItem(item);
        }
        return (

            <StyledIconButton
                disabled={disabled}
                color="primary"
                className="cart-item__remove"
                
                onClick={() => removeItem(rowData)}>
                REMOVE
            </StyledIconButton>
        );
    };
    useEffect(() => {
        setQuantityList(initQuantityData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps]);

    useEffect(() => {
        return () => {
            cancels.forEach((cancel) => cancel());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <ul className="slideout-cart__list cart-items">
    {dataProps.map((rowData) => {
            let isReplacementPartProduct = false;
            const productIsReplacementPart:any = () => {
                if(rowData && rowData?.attributes)  { 
                    rowData?.attributes?.map((att:any, index:number) => {
                        if(att.identifier.includes('AssociatedCharger')) {
                          isReplacementPartProduct = true;
                        } 
                    });
                }  
                return isReplacementPartProduct; 
            };
            return <li className="cart-item flex">
                <div className="cart-item__graphic text-align-center">
                    <a className="cart-item__image" href={rowData.seo.href}>
                        {/* <img src="//cdn.shopify.com/s/files/1/0554/0767/3429/products/03310c6e-89c5-48ad-8162-c36165408615.jpg?crop=center&amp;height=180&amp;v=1647356186&amp;width=180" alt="Ford Connected Charge Station" width="180" height="180" loading="lazy"/> */}
                        <img alt={rowData.name}
                        src={rowData.thumbnail && rowData.thumbnail}/>
                                            
                    </a>
                    <DeleteActionCell rowData={rowData} />
                </div>
                <div className="cart-item__information flex f-dir-column f-space-between f-stretch">
                    <div>
                        <p className="cart-item__sku">Part No: {rowData.partNumber}</p>
                        <Link className="cart-item__title" to={rowData.seo.href}>{rowData.name}</Link>
                        <p className="cart-item__price">
                        <FormattedPriceDisplay
                                                min={parseFloat(rowData.orderItemPrice)}
                                                currency={rowData.currency}
                                            />
                        </p>
                        <p className="cart-item__disclaimer">
                            {rowData.attributes && rowData.attributes.map((value:any)=>(
                                value.identifier=="CartMessage" &&
                                value.values.map((v:any)=>
                                v.value
                                )
                            ))}
                        </p>
                    </div>
                    <div className="cart-item__quantity">
                        <label>Quantity</label>
                        { !productIsReplacementPart() ? <input type="number" name="updates[]" id="updates_39942272876629:fdab9cd9a3b0e39b7eda7ed8cbd2f098" value={rowData?.quantity && parseInt(rowData.quantity)} min="0" aria-label="Quantity" readOnly/> :
                        <QuantityCell
                            rowData={rowData}
                        /> }
                    </div>
                </div>
            </li>
        }
    )}
    </ul>




        
    );
};
